import * as React from "react";
import { HeaderProps } from '../types';

const Header = (props: HeaderProps) => {
    const { image, imageStyles } = props;
    const { width, position, margin } = imageStyles;

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: position === "left" ? "flex-start" : position === "right" ? "flex-end" : "center",
                width: "100%",
                position: "absolute",
                top: position === "bottom-center" ? "" : "5%",
                bottom: position === "bottom-center" ? "5%" : "",
            }}
        >
            <img
                src={image}
                style={{
                    width,
                    height: "auto",
                    margin,
                }}
                alt="header"
            />
        </div>
    );
};

export default Header;
