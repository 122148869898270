import * as React from "react";
import { FooterProps } from '../types';


const Footer = (props: FooterProps) => {
    const { image, imageStyles } = props;
    const { width, position, margin } = imageStyles;

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: position === "left" ? "flex-start" : position === "right" ? "flex-end" : "center",
                width: "100%",
                position: "absolute",
                bottom: "5%",
            }}
        >
            {!!image && 
                <img
                    src={image}
                    style={{
                        width,
                        height: "auto",
                        margin,
                    }}
                    alt="footer"
                />
            }
        </div>
    );
};

export default Footer;