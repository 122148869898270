import * as React from "react";

const LogoImageSrc = require("../res/loading.png");

const LoadingView = () => {
	return (
		<div
			style={{
				backgroundColor: "white",
				position: "fixed",
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				color: "white",
				opacity: 1.0,
				pointerEvents: "none",
				transition: "0.5s all",
				zIndex: 1,
				
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<img
				src={LogoImageSrc}
				style={{
					height: 75,
				}}
				className="pulse"
				alt="loading"
			/>
		</div>
	);
};

export default LoadingView;