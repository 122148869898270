import React, { useState, useEffect, CSSProperties } from 'react';
import { useFetch } from '../utils/fetch';
import { AcquiringUser } from '../types';

import Loading from './Loading';
import Header from './Header';
import Footer from './Footer';
import Item from './Item';

import { VatomData } from '../types';

import styled from '@emotion/styled';
import '../index.css';

const QRCode = require('qrcode.react');

const containerStyle: CSSProperties = {
    boxSizing: "border-box",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundImage: ""
}

const Body: any = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props: any) => `${props.width}%`};
  height: ${(props: any) => `${props.height}vh`};
  position: absolute;
  top: ${(props: any) => props.top};
  bottom: ${(props: any) => props.bottom};
  right: ${(props: any) => props.right};
  left: ${(props: any) => props.left};
`;

const getBranding = () => {
  const billboard = window.location.hostname === "0.0.0.0" || window.location.hostname === "localhost" ? 'cocacola.billboard.vatominc.com' : window.location.hostname;
  const path = window.location.pathname.split("/")[1];
  const brand = !path ? "" : `/${path}`;
  return `https://tenants.vatominc.com/billboard/${billboard}${brand}/config.json`;
}

async function getPublicUserInfo(userId: string) {
  const res = await fetch(`https://users.vatom.com/${userId}`, { method: "GET" })
  if (res.status === 403 || res.status === 404) return undefined
  const json = await res.json()
  if (!res.ok) throw json
  return json
}

const App: React.FC = () => {
  const brandingUrl = getBranding();
  const [brandingData] = useFetch(brandingUrl);
  // for local debugging
  // const brandingData = require('../utils/sample-data.json');

  const isLoading: boolean = brandingData === undefined;
  const [acquiringUsers, setAcquiringUsers] = useState<any>({});

  // if `brandingData` changes to be defined, then call reset
  useEffect(() => {
    if (brandingData !== undefined) {
      reset(brandingData.objectDefinitions);
    }
  }, [brandingData]);

  const reset = (objectDefinitions: any) => {
    const acquiringUsersMap: any = {}

    objectDefinitions.forEach((v: VatomData) => {
      if (!acquiringUsersMap[v.objectDefinitionId]) {
        acquiringUsersMap[v.objectDefinitionId] = [];
      }
    });
    setAcquiringUsers(acquiringUsersMap);
  }

  useEffect(() => {
    const loc = window.location
    if (loc.hostname.includes('vatominc.com')) {
      window.location.assign(`${loc.protocol}//${loc.hostname.replace('vatominc.com', 'vatom.com')}${loc.pathname}`)
    }
  }, [])
  
  const businessId = brandingData ? brandingData.businessId : undefined;
  const resetTime = brandingData ? brandingData.resetTime || 4000 : 4000;
  const objectDefinitions = brandingData ? brandingData.objectDefinitions : undefined;
  
  useEffect(() => {
    if (!businessId || !objectDefinitions) return;
    
    function onAcquire(newUser: AcquiringUser, objectDefinitionId: string) {
      let isFull = false;

      setAcquiringUsers((prevUsersMap: any) => {
        const newUsers = prevUsersMap[objectDefinitionId] ? [...prevUsersMap[objectDefinitionId], newUser] : [];
        let count = 0;

        // eslint-disable-next-line
        objectDefinitions.forEach((v: any) => {
          if (v.name === objectDefinitionId) {
            count++;
          }
        })

        isFull = newUsers.length === count;

        // reassign prevUsers & update state
        prevUsersMap = {
          ...prevUsersMap,
          [objectDefinitionId]: newUsers,
        }

	console.log("assigning new acquiringUsers", prevUsersMap);
        return prevUsersMap;

      });

      if (isFull) {
        setTimeout(() => reset(objectDefinitions), resetTime);
      }
    }


  const socket = new WebSocket("wss://ws.api.vatominc.com");

    socket.addEventListener("open", function handleOpen() {
      console.log("websocket open");
    });

    socket.addEventListener("close", function handleClose() {
      console.log("websocket close");
    });
    
    socket.addEventListener("message", async function handleMessage(event) {
      const message = JSON.parse(event.data);
      console.log("websocket received message", message);

      if (message.type === "event" && message.eventType === "connected") {
        const subscribeRequest = {
          type: "request",
          requestType: "subscribe",
          // requestData: { topic: `/b/${businessId}/billboard` },
          requestData: { topic: `/b/nQwtevgfOa/billboard` },
        };

        socket.send(JSON.stringify(subscribeRequest));
      } else if (message.type === "event" && message.eventType === "vatomClaimed") {
        let user = await getPublicUserInfo(message.eventData.ownerUserId);
        if (!user) user = { name: "", picture: "" };
        if (!user.name) user.name = "Guest";
        if (!user.picture) user.picture = "https://resources.vatom.com/system/defaults/icons/avatar.png";
        onAcquire(user, message.eventData.objectDefinitionId);
      }
    });
  }, [businessId, resetTime, objectDefinitions])
  

  // RENDERING

  if (isLoading) {
    return <Loading />
  }

  const { logoWidth, logoPosition, logoMargin, logoImage, fontSize, bodyWidth, bodyHeight, bodyTop, bodyBottom, bodyLeft, bodyRight } = brandingData;
  const { image: footerImage } = brandingData.footer || {};
  const { url: qrUrl } = brandingData.qr || {};
  

  const renderObjectDefinitions = (objectDefinitions: VatomData[], acquiringUsers: any, fontSize: any) => {
    const indexes: any = {};

    // initialize indexes (ex. { "pub_fqdn::Template::v1::Variation::v1": 0 })
    for (const objDef of objectDefinitions) {
      if (indexes[objDef.objectDefinitionId] === undefined) {
        indexes[objDef.objectDefinitionId] = 0;
      }
    }

    return objectDefinitions.map((objDef, key: number) => {
      const { objectDefinitionId } = objDef;
      // get and increment the index for this variation
      const acquiringUserIndex = indexes[objectDefinitionId];
      indexes[objectDefinitionId] = acquiringUserIndex + 1;

      const acquiringUser = acquiringUsers && acquiringUsers[objectDefinitionId] && acquiringUsers[objectDefinitionId][acquiringUserIndex];

      return (
        <Item
          index={key}
          data={objDef}
          acquiringUser={acquiringUser}
          key={key}
          fontSize={fontSize}
        />
      )
    });
  }

  return (
    <div style={{
      ...containerStyle,
      ...brandingData.theme.container
    }}>
      {logoImage &&
        <Header
          image={logoImage}
          imageStyles={{
            width: logoWidth,
            position: logoPosition,
            margin: logoMargin,
          }}
        />
      }

      {!!qrUrl ? 
        <div 
            style={{
              position: "absolute",
              ...brandingData.theme.qr
            }}
        >                                
            <QRCode style={{ width: "100%", height: "100%" }} value={qrUrl} includeMargin={true} data-qr-code={qrUrl}/>
        </div>
      : null }
      <Body
        width={bodyWidth || 80}
        height={bodyHeight || 60}
        top={bodyTop || null}
        bottom={bodyBottom || null}
        left={bodyLeft || null}
        right={bodyRight || null}
      >
        {renderObjectDefinitions(objectDefinitions, acquiringUsers, fontSize)}
      </Body>

      <Footer
        image={footerImage}
        imageStyles={brandingData.theme.footer || {}}
      />
    </div>
  );
}

export default App;
