import { useState, useEffect } from "react";

function useFetch(url: string, options?: RequestInit) {
	const [data, setData] = useState<any>(undefined);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		const fetchUrl = async () => {
			const response = await fetch(url, options);
			const resBody = await response.json();
			setData(resBody);
			setLoading(false);
		}
		fetchUrl();
		// eslint-disable-next-line
	}, []);
	return [data, loading];
}

export { useFetch };
